import React, { FormEvent, HTMLProps } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import Icon, { IconTypes } from '../../Icon/Icon';
import { LocaleNameSpaceKeys } from '../../../localization/translations';
import './style.scss';

export interface NativeSelectPropOptions {
  label: string;
  placeHolder?: string;
  selectProps?: HTMLProps<HTMLSelectElement>;
}

export interface SelectOption {
  label: string;
  value: string;
  selected?: boolean;
  disabled?: boolean;
}

interface OwnProps {
  id: string;
  className?: string;
  options: NativeSelectPropOptions;
  isInvalid?: boolean;
  validationMessage?: string;
  selectOptions: SelectOption[];
  onChange?: (...args: any[]) => any;
  showArrow?: boolean;
  required?: boolean;
}

type Props = OwnProps;

const NativeSelect: React.FC<Props> = ({
  className = '',
  id,
  options,
  onChange = () => {},
  isInvalid,
  validationMessage = '',
  selectOptions,
  showArrow = true,
  required,
}) => {
  const { t } = useTranslation();

  const handleOnChange = (event: FormEvent<HTMLSelectElement>) => {
    onChange(event.currentTarget.value, id);
  };

  const errorMessage =
    !!isInvalid && validationMessage ? (
      <ErrorMessage id={`error-${id}`}>{validationMessage}</ErrorMessage>
    ) : null;

  const NativeSelectClassNames = classNames('NativeSelect', className, {
    'NativeSelect__is-invalid': !!isInvalid,
  });

  const getSelectOptions = () => {
    return selectOptions.map((opt, i) => {
      return (
        <option key={`${opt.value}-${i}`} {...opt}>
          {opt.label}
        </option>
      );
    });
  };

  const inputAria = validationMessage ? { 'aria-describedby': `error-${id}` } : {};
  const requiredLabel = t(`${LocaleNameSpaceKeys.COMMON}:requiredField`);
  const label = required ? `${options.label} ${requiredLabel}` : options.label;

  return (
    <div className={NativeSelectClassNames}>
      <label htmlFor={id}>{label}</label>
      <div className="NativeSelect__group-wrapper">
        <select id={id} onChange={handleOnChange} {...options.selectProps} {...inputAria}>
          {getSelectOptions()}
        </select>
        {showArrow ? <Icon className="SelectIcon" type={IconTypes.ARROW_DOWN} /> : null}
      </div>
      {errorMessage}
    </div>
  );
};

export default NativeSelect;
